/* ----------------------------------------------------------------------------
js entry point
---------------------------------------------------------------------------- */
import animateOnScroll from "./_elements/animate-on-scroll.js";
import RandomShapes from "./_elements/random-shapes.js";
import ScrollSentinel from "./_elements/scroll-sentinel.js";
import SmallDetails from "./_elements/small-details.js";

// TODO import this better
document.addEventListener("DOMContentLoaded", () => {
  let imgs = document.querySelectorAll("img.zoomable");

  imgs.forEach((img) => {
    img.addEventListener("click", () => {
      let parent = img.parentNode;
      let newImg = img.cloneNode();
      let dialog = document.createElement("dialog");
      dialog.appendChild(newImg);
      parent.appendChild(dialog);
      dialog.showModal();

      dialog.addEventListener("click", (event) => {
        if (event.target == dialog) {
          dialog.close();
          dialog.remove();
        }
      });
    });
  });
});
